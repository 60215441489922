import { TCPOS } from "@constants";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { store } from "@store";

import { ErrorToast } from "@components/common/Toast";
import { ConfirmationRemoveProductModal } from "@components/ConfirmationRemoveProductModal";
import { AddProductConfirmation } from "@components/OrderTaking/Components/Dialogs/AddProductConfirmation";
import ShowDetailsDialog from "@components/OrderTaking/Components/Dialogs/ShowDetails/ShowDetailsDialog";
import { WorkflowDialog } from "@components/OrderTaking/Components/Dialogs/WorkflowDialog";
import { AddIconWrapper } from "@components/OrderTaking/Components/Icons/AddIcon";
import MessageIcon from "@components/OrderTaking/Components/Icons/MessageIcon";
import { RemoveIcon } from "@components/OrderTaking/Components/Icons/RemoveIcon";
import { MenuCompositionsLabels } from "@components/OrderTaking/Components/Products/Cart/MenuCompositionsLabels";
import {
    addItemCommandTcPos,
    productHaveItemOutOfStock,
    removeItemCommandTcPos,
    useToggle,
} from "@components/OrderTaking/Helpers";
import { imageWithFallback } from "@components/OrderTaking/Helpers/Commonfunction";
import {
    addToOrder,
    addToOrderworkflow,
    ClearWorkflowItem,
    memoizedOrderItems,
    removeFromOrder,
    SelectProduct,
    setIsCommentKeyboardActive,
    setIsModification,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

const width = 52;
const height = 54;

export default function SimpleProductCartPreview({
    product,
    setIsLocalKeyBoardOpened,
}) {
    const dispatchStore = useDispatch();
    const { devise } = useSnapshot(kioskStore);
    const { posEditor } = useSnapshot(store);
    const { globalColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    const [
        isConfirmationRemoveProductModalOpen,
        setIsConfirmationRemoveProductModalOpen,
    ] = React.useState(false);
    const {
        isCommentProductActive,
        isConfirmationPopupForDeletingAnArticleActive,
        isShownProductCompositionDescription,
    } = useSelector((state) => state.settingSlice.productSetting, shallowEqual);
    const { globalcard } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const [isShowDetailsOpened, setIsShowDetailsOpened] = React.useState(false);
    const [path, setPath] = React.useState([]);
    const [isWorkflowDialogOpened, setIsWorkflowDialogOpened] = useToggle();
    const [
        isAddProductConfirmationDialogOpened,
        setIsAddProductConfirmationDialogOpened,
    ] = useToggle();

    const orderItems = useSelector((state) => memoizedOrderItems(state));

    const currentItem = orderItems.find(
        (x) => x.iuudOrder === product.iuudOrder
    );

    const isMenuProduct =
        (product.shopCart && Object.keys(product.shopCart).length !== 0) ||
        (Object.keys(product.shopCart).length === 0 &&
            product.haveBasicComposition) ||
        (Object.keys(product.shopCart).length === 0 && product.haveWorkflow);

    const isExpandMode = Object.values(product.shopCart).some(
        (item) => Object.keys(item?.compositions || {}).length > 0
    );

    const quantity =
        typeof currentItem !== "undefined" ? currentItem.quantity : 0;

    const productOrderHandler = (product) => {
        TCPOS === posEditor
            ? dispatchStore(removeItemCommandTcPos(product))
            : dispatchStore(removeFromOrder(product));
    };

    const cancelOrRemoveFromOrder = (product) => {
        TCPOS === posEditor
            ? dispatchStore(removeItemCommandTcPos(product))
            : dispatchStore(removeFromOrder(product));
    };
    const isOutOfStockMenu = globalcard.items[product.iuud].outStock;

    function addProduct() {
        const isOutOfStock = productHaveItemOutOfStock(
            product,
            globalcard.items
        );
        if (
            Object.keys(product.shopCart).length !== 0 ||
            product.haveWorkflow ||
            product.haveBasicComposition
        ) {
            if (isOutOfStockMenu) {
                toast.dismiss();
                ErrorToast(t("Item out of stock"));
                return;
            }

            if (isOutOfStock) {
                handleOnCancelAddConfirmation();
                return;
            }
            setIsAddProductConfirmationDialogOpened();
            return;
        }
        if (TCPOS === posEditor) {
            dispatchStore(addItemCommandTcPos(product));
            return;
        }
        dispatchStore(addToOrder(product));
    }
    function removeProduct() {
        if (isConfirmationPopupForDeletingAnArticleActive) {
            setIsConfirmationRemoveProductModalOpen(true);
            return;
        }
        handleRemoveProduct();
    }

    function handleRemoveProduct() {
        currentItem.quantity > 1
            ? productOrderHandler(product, -1)
            : cancelOrRemoveFromOrder(product);
    }
    function handleAddProductOnCLickEvent() {
        setIsAddProductConfirmationDialogOpened();
    }

    function handleShowDetailOnClickEvent() {
        const isExpandMode = Object.values(product.shopCart).some(
            (item) => Object.keys(item?.compositions || {}).length > 0
        );
        if (isExpandMode) {
            setIsShowDetailsOpened(!isShowDetailsOpened);
            setPath([]);
            return;
        }
        dispatchStore(ClearWorkflowItem());
        dispatchStore(setIsModification(true));
        dispatchStore(addToOrderworkflow(product));

        dispatchStore(setWorkflowData([]));
        dispatchStore(SelectProduct(product));
        setIsWorkflowDialogOpened(true);
    }
    const handleWorkDialogCloseEvent = () => {
        dispatchStore(ClearWorkflowItem());
        dispatchStore(SelectProduct({}));
        dispatchStore(setWorkflowData([]));
        setIsWorkflowDialogOpened();
    };
    const handleOnCancelAddConfirmation = () => {
        dispatchStore(setIsModification(false));
        dispatchStore(
            addToOrderworkflow({
                ...product,
                date: Date.now(),
                price: product.defaultPrice,
                quantity: 1,
                shopCart: {},
                workflow: {},
            })
        );
        dispatchStore(setWorkflowData([]));
        dispatchStore(SelectProduct({ ...product }));
        setIsWorkflowDialogOpened();
    };

    function handleAddCommentOnClickEvent() {
        setIsLocalKeyBoardOpened(true);
        dispatchStore(
            setIsCommentKeyboardActive({
                active: true,
                product: product,
            })
        );
    }
    return (
        <>
            {isWorkflowDialogOpened && (
                <WorkflowDialog
                    open={isWorkflowDialogOpened}
                    onClose={handleWorkDialogCloseEvent}
                    setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                />
            )}
            {isConfirmationRemoveProductModalOpen ? (
                <ConfirmationRemoveProductModal
                    setIsConfirmationRemoveProductModalOpen={
                        setIsConfirmationRemoveProductModalOpen
                    }
                    isConfirmationRemoveProductModalOpen={
                        isConfirmationRemoveProductModalOpen
                    }
                    callback={handleRemoveProduct}
                />
            ) : null}
            <AddProductConfirmation
                open={isAddProductConfirmationDialogOpened}
                onClose={handleAddProductOnCLickEvent}
                product={product}
                onCancel={handleOnCancelAddConfirmation}
            />
            {isShowDetailsOpened && (
                <ShowDetailsDialog
                    open={isShowDetailsOpened}
                    onClose={handleShowDetailOnClickEvent}
                    product={product}
                    path={path}
                    setPath={setPath}
                    setIsShowDetailsOpened={setIsShowDetailsOpened}
                    setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                />
            )}

            <div className="simple-product-preview">
                <div className="info-container-product">
                    <div className="info-image-price">
                        <img
                            className="imag-product-preview"
                            src={product.urlImage}
                            alt={product.urlImage}
                            onError={imageWithFallback}
                        />
                        <div className="price-product-preview">
                            {(quantity * product.price).toFixed(
                                devise.decimalPrice
                            ) + ` ${devise.deviseSymbole}`}
                        </div>
                    </div>
                    <div className="title-product-preview preview-text ">
                        {product.displayTitle}
                    </div>
                    {product?.selectedOptions?.length > 0 && (
                        <div className="info-options-product preview-text  ">
                            {product.selectedOptions.join(",")}
                        </div>
                    )}
                </div>
                <div className="actions-composed-product-preview pl-3 pr-1">
                    {isMenuProduct ? (
                        <div
                            className="d-flex align-items-center"
                            style={{ gap: "5px" }}
                        >
                            {isShownProductCompositionDescription ? (
                                <MenuCompositionsLabels
                                    compositions={product.shopCart}
                                    shopCart={true}
                                />
                            ) : (
                                <>
                                    <button
                                        className="see-details"
                                        style={{ backgroundColor: globalColor }}
                                        onClick={handleShowDetailOnClickEvent}
                                    >
                                        {t(
                                            isExpandMode
                                                ? "See details"
                                                : "Modify"
                                        )}
                                    </button>

                                    {isCommentProductActive === true &&
                                    product?.haveComment === true ? (
                                        <div
                                            className="d-flex justify-content-center align-content-center p-1"
                                            style={{
                                                backgroundColor:
                                                    product.comment !== ""
                                                        ? globalColor
                                                        : "gray",

                                                width: "55px",
                                                height: "55px",
                                                color: "white",

                                                borderRadius: " 10px",
                                            }}
                                            onClick={
                                                handleAddCommentOnClickEvent
                                            }
                                        >
                                            <MessageIcon
                                                fill="#FFF"
                                                width="45"
                                                height="45"
                                            />
                                        </div>
                                    ) : null}
                                </>
                            )}
                        </div>
                    ) : isCommentProductActive === true &&
                      product?.haveComment === true ? (
                        <button
                            className="see-details ml-3"
                            style={{
                                background:
                                    product.comment !== ""
                                        ? globalColor
                                        : "gray",
                                color: "white",
                            }}
                            onClick={handleAddCommentOnClickEvent}
                        >
                            {product.comment !== ""
                                ? t("Edit comment")
                                : t("Enter comment")}
                        </button>
                    ) : (
                        <div
                            className="see-details ml-3"
                            style={{ visibility: "hidden" }}
                        ></div>
                    )}

                    {!product.disabled ? (
                        <div className="simple-product-operations">
                            <div className="remove-item">
                                <RemoveIcon
                                    width={width}
                                    height={height}
                                    onClick={removeProduct}
                                ></RemoveIcon>
                            </div>
                            {!product.isReward ? (
                                <>
                                    <div className="number-product-preview">
                                        {quantity}
                                    </div>
                                    <div className="add-item">
                                        <AddIconWrapper
                                            opacity={"1"}
                                            width={width}
                                            height={height}
                                            onClick={addProduct}
                                        />
                                    </div>
                                </>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
}
export const MemoizedSimpleProductCartPreview = React.memo(
    SimpleProductCartPreview
);
SimpleProductCartPreview.propTypes = {
    product: PropTypes.object.isRequired,
    setIsLocalKeyBoardOpened: PropTypes.func,
};
