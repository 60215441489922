import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { handleUserAuthentication } from "@helpers/general";

import useDebounce from "@hooks/useDebounce";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { ErrorLogInModalWrapper } from "@components/LocalSettings/ErrorLogInModalWrapper";
import { syncStore } from "@components/Synchronization/store";

import {
    kioskStore,
    setIsAuthenticationModalOpened,
    setIsConfirmationModalOpened,
    setIsLocalConfigModalOpened,
    setLocalProject,
    setNumericKeyboardInputValue,
    setOrderItemsLength,
    setQrCodeValue,
    setUserAuthInfo,
} from "@pages/Kiosk/store";

import "./index.css";

type usualNumericKeyboardWrapperType = {
    isLocalSetting?: boolean;
};

export function UsualNumericKeyboardWrapper({
    isLocalSetting,
}: usualNumericKeyboardWrapperType): JSX.Element {
    const [loyaltyCode, setLoyaltyCode] = React.useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const keyboardRef = React.useRef<any>(null);
    const [inputValue, setInputValue] = React.useState("");

    const {
        project: {
            template: { content },
        },
        isAuthenticationErrorModalOpened,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        authModalStepIndex,
        selectedAuthMode,
        numericKeyboardInputValue,
        isConfirmationModalOpened,
        qrCodeValue,
        customerLanguage,
        isAuthenticationModalOpened,
        isKeyBoardOpened,
        project,
    } = useSnapshot(kioskStore);
    const { selectedShopApp } = useSnapshot(syncStore);
    const dispatch = useDispatch();
    const debouncedQrcode = useDebounce(qrCodeValue, 50);

    const [isErrorLogInModalWrapperOpened, setIsErrorLogInModalWrapperOpened] =
        React.useState<boolean>(false);
    const { orderItems, totalPrice } = useSelector(
        // eslint-disable-next-line
        (state: any) => state.orderSlice.order
    );
    const isInformationModeBridgeNumberOrPagerNumber =
        kioskWays[navigationIndex].name === "informationModesConsommation" &&
        (selectedInformationMode === "pagerNumber" ||
            selectedInformationMode === "bridgeNumber");

    const disabledButtons =
        numericKeyboardInputValue.length >= 20 ||
        (isInformationModeBridgeNumberOrPagerNumber &&
            numericKeyboardInputValue.length >= 4);

    const [enterKeyDisabled, setEnterKeyDisabled] =
        React.useState<boolean>(false);

    const enterButtonDisabledAttribute = enterKeyDisabled
        ? [
              {
                  attribute: "disabled",
                  value: `${enterKeyDisabled}`,
                  buttons: "{enter}",
              },
          ]
        : [];

    function handleKeyboardOnChangeEvent(input: string) {
        const slicedInputValue = input.slice(0, 20);
        setLoyaltyCode(slicedInputValue);
        setNumericKeyboardInputValue(slicedInputValue);
        setInputValue(slicedInputValue);
        if (keyboardRef.current) {
            keyboardRef.current.setInput(input);
        }
    }

    function handleOnClickEnterButtonEvent() {
        if (
            isKeyBoardOpened === false &&
            isAuthenticationModalOpened === true
        ) {
            if (numericKeyboardInputValue === selectedShopApp.passcode) {
                setLocalProject(project);
                setIsAuthenticationModalOpened(false);
                setIsLocalConfigModalOpened(true);
            } else {
                setIsErrorLogInModalWrapperOpened(true);
            }
            return;
        }
        if (selectedAuthMode !== "") {
            const authModeInfo: AuthModeInfoType =
                selectedAuthMode === "phoneNumber"
                    ? {
                          phoneNumber: numericKeyboardInputValue,
                      }
                    : {
                          loyaltyCardNumber:
                              qrCodeValue === ""
                                  ? numericKeyboardInputValue
                                  : qrCodeValue,
                      };
            setOrderItemsLength(orderItems.length);
            setUserAuthInfo(authModeInfo);

            handleUserAuthentication(
                authModeInfo,
                kioskWays,
                navigationIndex,
                isAuthenticationAccepted,
                selectedSaleModeValue,
                selectedInformationMode,
                isOrderPaidWithLoyaltyAccountBalance,
                selectedRegulationModeValue,
                authModalStepIndex,
                isAuthenticationErrorModalOpened,
                content,
                Number(totalPrice),
                dispatch
            );
        } else {
            setIsConfirmationModalOpened(!isConfirmationModalOpened);
        }
    }

    function handleKeyboardOnKeyPressEvent(button: string) {
        if (button === "{enter}") {
            handleOnClickEnterButtonEvent();
        }
    }

    React.useEffect(() => {
        setEnterKeyDisabled(numericKeyboardInputValue.length === 0);
        if (
            !isLocalSetting &&
            selectedAuthMode === "loyaltyCard" &&
            debouncedQrcode.length === 13
        ) {
            handleOnClickEnterButtonEvent();
        }
    }, [numericKeyboardInputValue, debouncedQrcode]);

    function handleOnChangeEvent(e: React.ChangeEvent<HTMLInputElement>) {
        setQrCodeValue(e.target.value);
        setLoyaltyCode(e.target.value);
        setInputValue(e.target.value);
        setNumericKeyboardInputValue(e.target.value.slice(0, 20));
        if (keyboardRef.current) {
            keyboardRef.current.setInput(e.target.value);
        }
    }

    function getInputValue(): string {
        if (isLocalSetting) {
            return inputValue;
        }
        if (qrCodeValue === "") {
            return loyaltyCode;
        } else {
            return qrCodeValue;
        }
    }

    return (
        <>
            <ErrorLogInModalWrapper
                setIsErrorLogInModalWrapperOpened={
                    setIsErrorLogInModalWrapperOpened
                }
                isErrorLogInModalWrapperOpened={isErrorLogInModalWrapperOpened}
            />
            <FlexboxGrid
                className="numeric-keyboard-wrapper__clz flex-column"
                gap="30px"
                alignItemsCentered={true}
                justifyContentCentered={true}
            >
                <input
                    className="text-center"
                    type={isAuthenticationModalOpened ? "password" : "text"}
                    value={getInputValue()}
                    autoFocus
                    placeholder={
                        isAuthenticationModalOpened
                            ? t("PASSCODE", { lng: customerLanguage.name })
                            : t("Your number", { lng: customerLanguage.name })
                    }
                    style={{
                        width: "400px",
                        fontSize: "35px",
                        border: "unset",
                        borderBottom: "1px solid #00000029",
                        outline: "none",
                    }}
                    onChange={handleOnChangeEvent}
                />
                <Keyboard
                    keyboardRef={(r) => (keyboardRef.current = r)}
                    useButtonTag={true}
                    theme="numeric-keyboard__clz hg-theme-default hg-layout-numeric numeric-theme"
                    display={{ "{bksp}": "&#10006", "{enter}": "&#10003" }}
                    layoutName="default"
                    onChange={handleKeyboardOnChangeEvent}
                    disableCaretPositioning={true}
                    disableRowButtonContainers={true}
                    onKeyPress={handleKeyboardOnKeyPressEvent}
                    layout={{
                        default: [
                            "1 2 3",
                            "4 5 6",
                            "7 8 9",
                            "{bksp} 0 {enter}",
                        ],
                    }}
                    buttonAttributes={
                        disabledButtons === true
                            ? [
                                  {
                                      attribute: "disabled",
                                      value: "false",
                                      buttons: "1 2 3",
                                  },
                                  {
                                      attribute: "disabled",
                                      value: "false",
                                      buttons: "4 5 6",
                                  },
                                  {
                                      attribute: "disabled",
                                      value: "false",
                                      buttons: "7 8 9",
                                  },
                                  {
                                      attribute: "disabled",
                                      value: "false",
                                      buttons: "0",
                                  },
                              ]
                            : enterButtonDisabledAttribute
                    }
                />
            </FlexboxGrid>
        </>
    );
}
