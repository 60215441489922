/* eslint-disable @typescript-eslint/no-explicit-any */
import { CATALOGUE_MODE } from "@constants";
import useSWR from "swr";
import { snapshot } from "valtio";

import { setPosEditor, store } from "@store";

import {
    setPeripheralShopApp,
    syncStore,
} from "@components/Synchronization/store";

const fetcher = (url: string) =>
    fetch(url)
        .then((response) => response.json())
        .catch((error) => {
            console.log(`Error while fetching data, with message: ${error}`);
            return error;
        });

export function useProject(url?: string): {
    project: ProjectType;
} {
    const { middlewareApiUrl } = snapshot(store);
    const { selectedShopApp } = snapshot(syncStore);

    // get the data from the local `project` first
    // which is saved while the `synchronization`

    // catalog project visualization

    if (process.env.REACT_APP_MODE === CATALOGUE_MODE) {
        const franchise_uid = localStorage.getItem("viewADB-franchise_uid");
        const shop_uid = localStorage.getItem("viewADB-shop_uid");
        const urlCatalogue = `${process.env.REACT_APP_SETTINGS_APP_API}/settings/Kiosk_Catalogue?franchiseId=${franchise_uid}&shopId=${shop_uid}`;

        const { data } = useSWR([urlCatalogue], fetcher, {
            suspense: true,
            revalidateOnFocus: false,
        });
        let project: any = {};
        if (data?.length > 0) {
            project = data[0];
            return { project };
        }
        return project;
    }

    const shopDetails = JSON.parse(
        localStorage.getItem("shopDetail") as string
    );

    if (shopDetails !== null) {
        const settingsUrl = `${middlewareApiUrl}/settings/consomation?franchise_uuid=${shopDetails?.franchise_uid}&shop_uuid=${shopDetails?.shop_uid}`;

        const {
            data: { kiosk },
        } = useSWR([settingsUrl], fetcher, {
            suspense: true,
            revalidateOnFocus: false,
        });
        if (kiosk.length > 0) {
            setPosEditor(kiosk[0].posEditor);
        }
        const shopApp = kiosk.filter((kiosk: Record<string, string>) => {
            return kiosk.appId === selectedShopApp.appId;
        });
        setPeripheralShopApp({
            monetics: shopApp[0].monetics || {},
            printers: shopApp[0].printers || {},
            cash_managements: shopApp[0].cash_managements || {},
        });
        return { project: shopApp[0].project };
    }

    // @todo handle error case
    const { data: project } = useSWR([url as string], fetcher, {
        suspense: true,
        revalidateOnFocus: false,
    });

    return { project };
}
