import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getProductTitle } from "@components/OrderTaking/Helpers";

export function ProductTitle({ productName }) {
    const { isProductTitleActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );

    const title = getProductTitle(productName);

    return isProductTitleActive ? (
        <div
            className={"two-lines-preview-text"}
            style={{
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "Segoe UI",
                lineHeight: "1.2em",
                textAlign: "center",
            }}
        >
            {title}
        </div>
    ) : null;
}

ProductTitle.propTypes = {
    productName: PropTypes.object,
};
