/* eslint-disable @typescript-eslint/no-explicit-any */
import { transitions } from "@constants";
import { changeLanguage } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

import { setIsAppSynchronized, store } from "@store";

import { handleLedControl } from "@helpers/general";

import { stepDataContent } from "@hooks/useStepDataContent";
import { useUpdateProjectSteps } from "@hooks/useUpdateProjectSteps ";

import { SpinnerWrapper } from "@components/common/SpinnerWrapper";
import { setOrderTakingSetting } from "@components/OrderTaking/Helpers/Commonfunction";
import { setSetting } from "@components/OrderTaking/Store/feature";
import {
    setPeripheralShopApp,
    syncStore,
} from "@components/Synchronization/store";

import { StandbyStep } from "@pages/Kiosk/Steps/StandbyStep";
import {
    kioskStore,
    setIsProjectMustBeUpdated,
    setLocalProject,
    setProject,
} from "@pages/Kiosk/store";

export function StepComponent(): JSX.Element {
    const {
        kioskWays,
        navigationIndex,
        isLedActive,
        connectedLedPort,
        isProjectMustBeUpdated,
        isLocalConfigModalOpened,
        isAnimationIn,
        isAuthenticationModalOpened,
        isAppMustBeResynchronise,
    } = useSnapshot(kioskStore);

    const { techProblemModal } = useSnapshot(store);

    const { selectedShopApp } = useSnapshot(syncStore);
    const { middlewareApiUrl, isAppPaused } = useSnapshot(store);
    const dispatch = useDispatch();
    const catalogueIsLoading = useSelector(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: any) => state.orderSlice.catalogueIsLoading,
        shallowEqual
    );
    const Component = kioskWays[navigationIndex]?.stepContent;
    const chosenTransition = (transitions as TransitionsType)[
        kioskWays[navigationIndex].animation
    ];

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const shopDetail: any = JSON.parse(localStorage.getItem("shopDetail")!);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function getSettingProject() {
        const timestamp = new Date().getTime();
        console.log(
            `Setting new project from setting start on standby screen with url: ${middlewareApiUrl}/settings/kiosk?franchise_uuid=${shopDetail?.franchise_uid}&shop_uuid=${shopDetail?.shop_uid}&appId=${selectedShopApp?.appId}&timestamp=${timestamp}`
        );
        return fetch(
            `${middlewareApiUrl}/settings/kiosk?franchise_uuid=${shopDetail?.franchise_uid}&shop_uuid=${shopDetail?.shop_uid}&appId=${selectedShopApp?.appId}&timestamp=${timestamp}`,
            { cache: "no-store" }
        )
            .then((response) => response.json())
            .then((result) => {
                if (result !== undefined) {
                    if (result.project !== undefined) {
                        console.log(
                            `Setting new project: ${JSON.stringify(
                                result.project
                            )}`
                        );
                        setProject(result.project);
                        setPeripheralShopApp({
                            monetics: result.monetics,
                            printers: result.printers,
                            cash_managements: result.cash_managements,
                        });
                        changeLanguage(
                            (
                                result.project.template.content
                                    .languages as LanguagesType
                            ).items.filter(
                                (language) => language.isDefault === true
                            )[0].name
                        );
                        if (isLocalConfigModalOpened) {
                            setLocalProject(result.project);
                        }

                        useUpdateProjectSteps(result.project);

                        dispatch(
                            setSetting(
                                setOrderTakingSetting(
                                    result.project.template.pages.ways
                                        .orderTaking as PageType,

                                    (
                                        result.project.template.content
                                            .generalDesign as GeneralDesignType
                                    ).colors[0].content,
                                    (
                                        result.project.template.content
                                            .generalDesign as GeneralDesignType
                                    ).colors[1].content
                                )
                            )
                        );
                        setIsProjectMustBeUpdated({
                            setting: false,
                            peripheral: false,
                        });
                        console.log(
                            "Setting new project from `setting` ends with success on standby screen"
                        );
                    }
                }
            })
            .catch((error) =>
                console.log(
                    `Error while updating project setting from setting, with message: ${JSON.stringify(
                        error
                    )}`
                )
            )
            .finally(() => {
                console.log("Project Setting update DONE successfully");
                setIsProjectMustBeUpdated({
                    setting: false,
                    peripheral: false,
                });
            });
    }

    function updateKioskPerphiral() {
        const timestamp = new Date().getTime();
        const url = `${middlewareApiUrl}/newPeripheralConfig?appId=${selectedShopApp.appId}&timestamp=${timestamp}`;
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                console.log(
                    "Peripheral data after socket notification: ",
                    JSON.stringify(data)
                );
                if (data.error === true) return;
                // set new peripheral configuration
                setPeripheralShopApp(data);
                setIsProjectMustBeUpdated({
                    setting: false,
                    peripheral: false,
                });

                console.log(
                    `Successfully update peripheral configuration for shop app: ${selectedShopApp.name} with ID: ${selectedShopApp.appId}`
                );
            })
            .catch((error) => {
                console.log(
                    `Error while updating project setting from setting, with message: ${error}`
                );
            })
            .finally(() => {
                console.log("Project Peripheral update DONE successfully");
                setIsProjectMustBeUpdated({
                    setting: false,
                    peripheral: false,
                });
            });
    }

    if (isProjectMustBeUpdated.setting && navigationIndex === 0) {
        getSettingProject();
    }
    if (isProjectMustBeUpdated.peripheral && navigationIndex === 0) {
        updateKioskPerphiral();
    }
    if (isAppMustBeResynchronise && navigationIndex === 0) {
        const localStorageItems = [
            "shopDetail",
            "selectedShopApp",
            "selectedEntityChild",
            "uuidSession",
            "uidModification",
            "uuidSession",
            "selectedEntityChild",
            "project",
            "peripheralShopApp",
            "ledConfig",
            "connectedUser",
        ];
        localStorageItems.map((item: any) => {
            localStorage.removeItem(item);
        });
        setIsAppSynchronized(false);
        window.location.reload();
    }
    React.useEffect(() => {
        stepDataContent(navigationIndex, kioskWays);
        if (
            !isProjectMustBeUpdated.setting &&
            !isProjectMustBeUpdated.peripheral &&
            navigationIndex === 0
        ) {
            (window as RendererWindow).electronAPI?.checkForUpdate();
        }
    }, [navigationIndex]);

    React.useEffect(() => {
        if (
            isLedActive &&
            navigationIndex === 0 &&
            !isAppPaused &&
            !isLocalConfigModalOpened &&
            !isAuthenticationModalOpened &&
            !techProblemModal.isTechProblemModalOpened
        ) {
            handleLedControl(connectedLedPort, "Green");
        }
    });

    if (
        catalogueIsLoading ||
        (isProjectMustBeUpdated.setting && navigationIndex === 0)
    ) {
        console.log(
            `Spinner Loading , catalogueIsLoading: ${catalogueIsLoading} | ((setting: ${
                isProjectMustBeUpdated.setting
            } & indexnavigation must be zero: ${navigationIndex === 0})`
        );
        return <SpinnerWrapper />;
    }

    if (Component === undefined || navigationIndex === 0) {
        return <StandbyStep />;
    }

    return (
        <CSSTransition
            in={isAnimationIn}
            appear={true}
            timeout={chosenTransition.timeout}
            classNames={chosenTransition.className}
        >
            <Component />
        </CSSTransition>
    );
}
